import {useQuery} from '@apollo/client';
import {GET_CATEGORIES} from "../graphql";


function TitleBar(props) {
    const {loading, error, data} = useQuery(GET_CATEGORIES);
    if (loading) return ;

    if (error) return <p>Error :(</p>;
    return (
        <div id="titlebar" className="gradient">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <h2>{props.title}</h2><span>Asian Wedding Vendors</span>

                        <nav id="breadcrumbs">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li>Listings</li>
                            </ul>
                        </nav>

                    </div>

                </div>

            </div>

            <div className="clearfix"></div>

        </div>



    );
}


export default TitleBar;



