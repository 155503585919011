import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Index from "./components/Index";
import Footer from "./components/Footer";
import SignIn from "./components/SignIn";


function App() {


  return (
    <div>
        <NavBar/>
        <TitleBar/>
        <SignIn/>
        <Footer/>
    </div>
  );
}

export default App;
