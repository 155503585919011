import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Footer from "./components/Footer";
import {useParams, useSearchParams} from "react-router-dom";
import ListingBody from "./components/ListingBody";
import {useQuery} from "@apollo/client";
import {GET_SERVICES_BY_CATEGORY, GET_VENDOR_BY_SLUG} from "./graphql";
import Error from "./Error";


function Listing() {

    const [searchParams, setSearchParams] = useSearchParams();
    let search_param = searchParams.get("search")
    if (search_param == null) {
        search_param = "Make Up Artist"
    }
    let location_param = searchParams.get("location")

    if (location_param == null || location_param == "") {
        location_param = "United Kingdom"
    }
    let params = useParams(); // calling the hook

     let slug = "f"
    if (params.slug) {
        slug = params.slug;
    }



      const {loading, error, data} = useQuery(GET_VENDOR_BY_SLUG, {
        variables: {"slug": slug}
    });


        const {loading2, error2, services_data} = useQuery(GET_SERVICES_BY_CATEGORY, {
    variables: {"slug": slug}
    });



    if (loading2) return ;

    if (error2) return <Error/>;

        if (loading) return ;

    if (error) return <Error/>;

    return (

        <div>

            <NavBar/>
            <TitleBar/>

            <ListingBody search={search_param} location={location_param} data={data} services={services_data}/>
            <Footer/>
        </div>
    );
}

export default Listing;
