import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Index from "./components/Index";
import Footer from "./components/Footer";
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES, GET_CATEGORIES_BY_NAME} from "./graphql";
import Error from "./Error";
import VendorsByCategory from "./VendorsByCategory";


function CategoryListings(prop) {

    const [searchParams, setSearchParams] = useSearchParams();
    let search_param = searchParams.get("search")
    if (search_param == null) {
        search_param = "Make Up Artist"
    }
    let location_param = searchParams.get("location")

    if (location_param == null || location_param == "") {
        location_param = "United Kingdom"
    }
    let params = useParams(); // calling the hook
    let slug = "f"
    if (params.slug) {
        slug = params.slug;
    }
    ;
    let page = ""
    if (params.page) {
        page = params.page;
    } else {
        page = 1;

    }
    const {loading, error, data} = useQuery(GET_CATEGORIES_BY_NAME, {
        variables: {slug}
    });

    if (loading) return ;

    if (error) return <Error/>;


    return (
        <div>
            <NavBar/>
            <TitleBar title={data.categoryByName.name}/>
            <VendorsByCategory category={data.categoryByName.name} search={data.categoryByName.name}
                               location={location_param} page={page}/>
            <Footer/>
        </div>
    );
}

export default CategoryListings;
