function ListingOptions() {
    return (


        <div>
            <div className="col-md-6 col-xs-6">
                <div className="layout-switcher">
                    <a href="#" className="grid active"><i className="fa fa-th"></i></a>
                    <a href="listings-list-with-sidebar.html" className="list"><i
                        className="fa fa-align-justify"></i></a>
                </div>
            </div>

            <div className="col-md-6 col-xs-6">
                <div className="sort-by">
                    <div className="sort-by-select">
                        <select data-placeholder="Default order" className="chosen-select-no-single">
                            <option>Default Order</option>
                            <option>Highest Rated</option>
                            <option>Most Reviewed</option>
                            <option>Newest Listings</option>
                            <option>Oldest Listings</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ListingOptions;



