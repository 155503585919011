import {useQuery} from "@apollo/client";
import {GET_CATEGORIES} from "../graphql";
import {Link, Route, Router, Routes} from "react-router-dom";
import App from "../App";

function VendorsNav() {


    const {loading, error, data} = useQuery(GET_CATEGORIES);

    if (loading) return ;
    if (error) return <p>Error :(</p>;

    function subcategories(id) {
        if (data.categories[id]["childCategories"].length != 0){

            let links =  data.categories[id]["childCategories"].map(({name, slug}) =>
                    <li><Link to={`/asian-wedding-vendors/${slug.toLowerCase()}/`}>{name}</Link></li>

            )
            return (<ul>{links}</ul>)
    }}


    return data.categories.map(({name, menuOrder, childCategories, slug}, index) =>
        <li><Link to={`/asian-wedding-vendors/${slug.toLowerCase()}/`}>{name}</Link>
            {subcategories(index)}
        </li>

    );
}

export default VendorsNav;




