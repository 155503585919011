import SideBar from "./SideBar";
import Listings from "./Listings";

function SignIn() {
    return (

        <div className="container">
            <div className="row">
                <div className="col-lg-9 col-md-8 padding-right-30">
                    <h3 className="margin-top-0 margin-bottom-30">Personal Details</h3>

                    <div className="row">

                        <div className="col-md-6">
                            <label>First Name</label>
                            <input type="text" value=""/>
                        </div>

                        <div className="col-md-6">
                            <label>Last Name</label>
                            <input type="text" value=""/>
                        </div>

                        <div className="col-md-6">
                            <div className="input-with-icon medium-icons">
                                <label>E-Mail Address</label>
                                <input type="text" value=""/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="input-with-icon medium-icons">
                                <label>Phone</label>
                                <input type="text" value=""/>
                            </div>
                        </div>

                    </div>



                </div>
            <SideBar/>
            </div>

        </div>

    );
}

export default SignIn;




