import FullSearch from "./FullSearch";
import Listings from "./Listings";
import SideBar from "./SideBar";
import {useParams, useSearchParams} from "react-router-dom";
import ListingSideBar from "./ListingSideBar";
import {useQuery} from "@apollo/client";
import {GET_VENDOR_BY_SLUG} from "../graphql";
import Error from "../Error";
import {Helmet} from "react-helmet";
import Services from "./Services";
import services from "./Services";

function ListingBody(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    let search_param = searchParams.get("search")
    if (search_param == null) {
        search_param = props.data.vendorBySlug.categories[0].name
    }
    let location_param = searchParams.get("location")

    if (location_param == null || location_param == "") {
        location_param = "United Kingdom"
    }

    return (

        <div class="container">
            <Helmet>
                <title>{props.data.vendorBySlug.name} - {props.data.vendorBySlug.categories[0].name} - Kalyanam </title>
                <meta name="description" content={props.data.vendorBySlug.seoDescription}></meta>
                <meta name="keywords" content={props.data.vendorBySlug.keywords}></meta>
            </Helmet>
            <FullSearch search={search_param} location={location_param}/>
            {/*{data.name}*/}
            <div class="row sticky-wrapper">
                <div class="col-lg-8 col-md-8 padding-right-30">
                    <div id="titlebar" class="listing-titlebar">
                        <div class="listing-titlebar-title">
                            <h2>{props.data.vendorBySlug.name} <span
                                class="listing-tag">{props.data.vendorBySlug.categories[0].name}</span></h2>
                            <span>
						<a href="#listing-location" class="listing-address">
							<i class="fa fa-map-marker"></i>
                            {props.data.vendorBySlug.postcode}
						</a>
					</span>
                            <div class="star-rating" data-rating="5">
                                <div class="rating-counter"><a href="#listing-reviews">(31 reviews)</a></div>
                            </div>
                        </div>
                    </div>

                    <div id="listing-nav" class="listing-nav-container">
                        <ul class="listing-nav">
                            <li><a href="#listing-overview" class="active">Overview</a></li>
                            <li><a href="#listing-gallery">Gallery</a></li>
                            <li><a href="#listing-pricing-list">Pricing</a></li>
                            <li><a href="#listing-location">Location</a></li>
                            {/*<li><a href="#listing-reviews">Reviews</a></li>*/}
                            {/*<li><a href="#add-review">Add Review</a></li>*/}
                        </ul>
                    </div>

                    <div id="listing-overview" class="listing-section">


                        <p>
                            {props.data.vendorBySlug.description}
                        </p>

                        <h3 className="listing-desc-headline">Services</h3>
                        <Services data={props.data.vendorBySlug}/>
                    </div>


                    <div id="listing-gallery" class="listing-section">
                        <h3 class="listing-desc-headline margin-top-70">Gallery</h3>
                        <div class="listing-slider-small mfp-gallery-container margin-bottom-0">
                            <a href="images/single-listing-01.jpg"
                               data-background-image="http://www.vasterad.com/themes/listeo_updated/images/single-listing-01.jpg"
                               class="item mfp-gallery" title="Title 1"></a>
                            <a href="images/single-listing-02.jpg"
                               data-background-image="http://www.vasterad.com/themes/listeo_updated/images/single-listing-02.jpg"
                               class="item mfp-gallery" title="Title 3"></a>
                            <a href="images/single-listing-03.jpg"
                               data-background-image="http://www.vasterad.com/themes/listeo_updated/images/single-listing-03.jpg"
                               class="item mfp-gallery" title="Title 2"></a>
                            <a href="images/single-listing-04.jpg"
                               data-background-image="http://www.vasterad.com/themes/listeo_updated/images/single-listing-04.jpg"
                               class="item mfp-gallery" title="Title 4"></a>
                        </div>
                    </div>


                    <div id="listing-pricing-list" class="listing-section">
                        <h3 class="listing-desc-headline margin-top-70 margin-bottom-30">Pricing</h3>


                        <div class="pricing-list-container">

                        </div>
                    </div>


                    <div id="listing-location" class="listing-section">
                        <h3 class="listing-desc-headline margin-top-60 margin-bottom-30">Location</h3>

                        <div id="singleListingMap-container">
                            <div id="singleListingMap" data-latitude="40.70437865245596"
                                 data-longitude="-73.98674011230469" data-map-icon="im im-icon-Hamburger"></div>
                            <a href="#" id="streetView">Street View</a>
                        </div>
                    </div>

                    {/*<div id="listing-reviews" class="listing-section">*/}
                    {/*    <h3 class="listing-desc-headline margin-top-75 margin-bottom-20">Reviews <span>(12)</span></h3>*/}

                    {/*    <div class="clearfix"></div>*/}

                    {/*    <section class="comments listing-reviews">*/}

                    {/*        <ul>*/}
                    {/*            <li>*/}
                    {/*                <div class="avatar"><img*/}
                    {/*                    src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&amp;s=70"*/}
                    {/*                    alt=""/></div>*/}
                    {/*                <div class="comment-content">*/}
                    {/*                    <div class="arrow-comment"></div>*/}
                    {/*                    <div class="comment-by">Kathy Brown<span class="date">June 2017</span>*/}
                    {/*                        <div class="star-rating" data-rating="5"></div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere*/}
                    {/*                        tristique sem, eu ultricies tortor imperdiet vitae. Curabitur lacinia neque*/}
                    {/*                        non metus</p>*/}

                    {/*                    <div class="review-images mfp-gallery-container">*/}
                    {/*                        <a href="images/review-image-01.jpg" class="mfp-gallery"><img*/}
                    {/*                            src="images/review-image-01.jpg" alt=""></img></a>*/}
                    {/*                    </div>*/}
                    {/*                    <a href="#" class="rate-review"><i class="sl sl-icon-like"></i> Helpful*/}
                    {/*                        Review <span>12</span></a>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <div class="avatar"><img*/}
                    {/*                    src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&amp;s=70"*/}
                    {/*                    alt=""/></div>*/}
                    {/*                <div class="comment-content">*/}
                    {/*                    <div class="arrow-comment"></div>*/}
                    {/*                    <div class="comment-by">John Doe<span class="date">May 2017</span>*/}
                    {/*                        <div class="star-rating" data-rating="4"></div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim.*/}
                    {/*                        Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc*/}
                    {/*                        tellus sollicitudin mauris.</p>*/}
                    {/*                    <a href="#" class="rate-review"><i class="sl sl-icon-like"></i> Helpful*/}
                    {/*                        Review <span>2</span></a>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <div class="avatar"><img*/}
                    {/*                    src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&amp;s=70"*/}
                    {/*                    alt=""/></div>*/}
                    {/*                <div class="comment-content">*/}
                    {/*                    <div class="arrow-comment"></div>*/}
                    {/*                    <div class="comment-by">Kathy Brown<span class="date">June 2017</span>*/}
                    {/*                        <div class="star-rating" data-rating="5"></div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere*/}
                    {/*                        tristique sem, eu ultricies tortor imperdiet vitae. Curabitur lacinia neque*/}
                    {/*                        non metus</p>*/}

                    {/*                    <div class="review-images mfp-gallery-container">*/}
                    {/*                        <a href="images/review-image-02.jpg" class="mfp-gallery"><img*/}
                    {/*                            src="images/review-image-02.jpg" alt=""></img></a>*/}
                    {/*                        <a href="images/review-image-03.jpg" class="mfp-gallery"><img*/}
                    {/*                            src="images/review-image-03.jpg" alt=""></img></a>*/}
                    {/*                    </div>*/}
                    {/*                    <a href="#" class="rate-review"><i class="sl sl-icon-like"></i> Helpful*/}
                    {/*                        Review <span>4</span></a>*/}
                    {/*                </div>*/}
                    {/*            </li>*/}

                    {/*            <li>*/}
                    {/*                <div class="avatar"><img*/}
                    {/*                    src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&amp;s=70"*/}
                    {/*                    alt=""/></div>*/}
                    {/*                <div class="comment-content">*/}
                    {/*                    <div class="arrow-comment"></div>*/}
                    {/*                    <div class="comment-by">John Doe<span class="date">May 2017</span>*/}
                    {/*                        <div class="star-rating" data-rating="5"></div>*/}
                    {/*                    </div>*/}
                    {/*                    <p>Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim.*/}
                    {/*                        Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc*/}
                    {/*                        tellus sollicitudin mauris.</p>*/}
                    {/*                    <a href="#" class="rate-review"><i class="sl sl-icon-like"></i> Helpful*/}
                    {/*                        Review</a>*/}
                    {/*                </div>*/}

                    {/*            </li>*/}
                    {/*        </ul>*/}
                    {/*    </section>*/}

                    {/*    <div class="clearfix"></div>*/}
                    {/*    <div class="row">*/}
                    {/*        <div class="col-md-12">*/}
                    {/*            <div class="pagination-container margin-top-30">*/}
                    {/*                <nav class="pagination">*/}
                    {/*                    <ul>*/}
                    {/*                        <li><a href="#" class="current-page">1</a></li>*/}
                    {/*                        <li><a href="#">2</a></li>*/}
                    {/*                        <li><a href="#"><i class="sl sl-icon-arrow-right"></i></a></li>*/}
                    {/*                    </ul>*/}
                    {/*                </nav>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div class="clearfix"></div>*/}
                    {/*</div>*/}


                    {/*<div id="add-review" class="add-review-box">*/}

                    {/*    <h3 class="listing-desc-headline margin-bottom-20">Add Review</h3>*/}

                    {/*    <span class="leave-rating-title">Your rating for this listing</span>*/}

                    {/*    <div class="row">*/}
                    {/*        <div class="col-md-6">*/}
                    {/*            <div class="clearfix"></div>*/}
                    {/*            <div class="leave-rating margin-bottom-30">*/}
                    {/*                <input type="radio" name="rating" id="rating-1" value="1"/>*/}
                    {/*                <label for="rating-1" class="fa fa-star"></label>*/}
                    {/*                <input type="radio" name="rating" id="rating-2" value="2"/>*/}
                    {/*                <label for="rating-2" class="fa fa-star"></label>*/}
                    {/*                <input type="radio" name="rating" id="rating-3" value="3"/>*/}
                    {/*                <label for="rating-3" class="fa fa-star"></label>*/}
                    {/*                <input type="radio" name="rating" id="rating-4" value="4"/>*/}
                    {/*                <label for="rating-4" class="fa fa-star"></label>*/}
                    {/*                <input type="radio" name="rating" id="rating-5" value="5"/>*/}
                    {/*                <label for="rating-5" class="fa fa-star"></label>*/}
                    {/*            </div>*/}
                    {/*            <div class="clearfix"></div>*/}
                    {/*        </div>*/}

                    {/*        <div class="col-md-6">*/}
                    {/*            <div class="add-review-photos margin-bottom-30">*/}
                    {/*                <div class="photoUpload">*/}
                    {/*                    <span><i class="sl sl-icon-arrow-up-circle"></i> Upload Photos</span>*/}
                    {/*                    <input type="file" class="upload"/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <form id="add-comment" class="add-comment">*/}
                    {/*        <fieldset>*/}

                    {/*            <div class="row">*/}
                    {/*                <div class="col-md-6">*/}
                    {/*                    <label>Name:</label>*/}
                    {/*                    <input type="text" value=""/>*/}
                    {/*                </div>*/}

                    {/*                <div class="col-md-6">*/}
                    {/*                    <label>Email:</label>*/}
                    {/*                    <input type="text" value=""/>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*            <div>*/}
                    {/*                <label>Review:</label>*/}
                    {/*                <textarea cols="40" rows="3"></textarea>*/}
                    {/*            </div>*/}

                    {/*        </fieldset>*/}

                    {/*        <button class="button">Submit Review</button>*/}
                    {/*        <div class="clearfix"></div>*/}
                    {/*    </form>*/}

                    {/*</div>*/}


                </div>


                <ListingSideBar/>

            </div>
        </div>

    );
}

export default ListingBody;




