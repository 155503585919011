import VendorsNav from "./VendorsNav";

function NavBar() {
    return (

        <header id="header-container">
            <div id="header">
                <div className="container">

                    <div className="left-side">

                        <div id="logo">
                            <a href="/"><img src="/logo.png" alt=""/></a>
                        </div>

                        <div className="mmenu-trigger">
                            <button className="hamburger hamburger--collapse" type="button">
						<span className="hamburger-box">
						</span>
                            </button>
                        </div>


                        <div className="clearfix"></div>

                    </div>

                    <div className="right-side">
                        <div className="header-widget">
                            <nav id="navigation" className="style-1">
                                <ul id="responsive">

                                    <li><a href="/">Home</a></li>
                                    <li><a href="https://blog.kalyanam.co.uk">Blog</a></li>


                                    <li className="vendor-menu-item"><a className="current">Vendors</a>
                                        <ul>

                                            <VendorsNav></VendorsNav>
                                        </ul>
                                    </li>
                                    <li className="vendor-menu-item2"><a className="current">Vendors</a></li>


                                </ul>
                            </nav>

                        </div>
                    </div>

                    <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">

                        <div className="small-dialog-header">
                            <h3>Sign In</h3>
                        </div>

                        <div className="sign-in-form style-1">

                            <ul className="tabs-nav">
                                <li className=""><a href="#tab1">Log In</a></li>
                                <li><a href="#tab2">Register</a></li>
                            </ul>

                            <div className="tabs-container alt">

                                <div className="tab-content" id="tab1" style={{display: 'none'}}>
                                    <form method="post" className="login">

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="username">Username:
                                                <i className="im im-icon-Male"></i>
                                                <input type="text" className="input-text" name="username" id="username"
                                                       defaultValue=""/>
                                            </label>
                                        </p>

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="password">Password:
                                                <i className="im im-icon-Lock-2"></i>
                                                <input className="input-text" type="password" name="password"
                                                       id="password"/>
                                            </label>
                                            <span className="lost_password">
										<a href="#">Lost Your Password?</a>
									</span>
                                        </p>

                                        <div className="form-row">
                                            <input type="submit" className="button border margin-top-5" name="login"
                                                   defaultValue="Login"/>
                                            <div className="checkboxes margin-top-10">
                                                <input id="remember-me" type="checkbox" name="check"/>
                                                <label htmlFor="remember-me">Remember Me</label>
                                            </div>
                                        </div>

                                    </form>
                                </div>

                                <div className="tab-content" id="tab2" style={{display: 'none'}}>

                                    <form method="post" className="register">

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="username2">Username:
                                                <i className="im im-icon-Male"></i>
                                                <input type="text" className="input-text" name="username" id="username2"
                                                       defaultValue=""/>
                                            </label>
                                        </p>

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="email2">Email Address:
                                                <i className="im im-icon-Mail"></i>
                                                <input type="text" className="input-text" name="email" id="email2"
                                                       defaultValue=""/>
                                            </label>
                                        </p>

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="password1">Password:
                                                <i className="im im-icon-Lock-2"></i>
                                                <input className="input-text" type="password" name="password1"
                                                       id="password1"/>
                                            </label>
                                        </p>

                                        <p className="form-row form-row-wide">
                                            <label htmlFor="password2">Repeat Password:
                                                <i className="im im-icon-Lock-2"></i>
                                                <input className="input-text" type="password" name="password2"
                                                       id="password2"/>
                                            </label>
                                        </p>

                                        <input type="submit" className="button border fw margin-top-10" name="register"
                                               defaultValue="Register"/>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>

);
}

export default NavBar;
