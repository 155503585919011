import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet';

const TITLE = 'My Page Title';
function HTMLHeader() {
      let params = useParams(); // calling the hook
    let page = ""
    if (params.page) {
        page = params.page;
    }
    return (
      <head> <Helmet>
          <title>Kalyanam - Find Vendors for Your Wedding!</title>
        </Helmet></head>

    );
}

export default HTMLHeader;
