import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Index from "./components/Index";
import Footer from "./components/Footer";
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_VENDORS_BY_CATEGORY, TEN_RANDOM_VENDORS} from "./graphql";


function App(prop) {

    const [searchParams, setSearchParams] = useSearchParams();
    let search_param = searchParams.get("search")
    if (search_param == null) {
        search_param = "Make Up Artist"
    }
    let location_param = searchParams.get("location")

    if (location_param == null || location_param == "") {
        location_param = "United Kingdom"
    }
    let params = useParams(); // calling the hook
    let page = ""
    if (params.page) {
        page = params.page;
    } else {
        page = 1;

    }
    ;


    const {loading, error, data} = useQuery(TEN_RANDOM_VENDORS, {
        variables: {"page": parseInt(page)}
    });
    if (loading) return ;

    if (error) return <p>Error :(</p>;


    return (
        <div>
            <NavBar/>
            <TitleBar title="Kalyanam"/>
            <Index data={data} search={search_param} location={location_param}/>
            <Footer/>
        </div>
    );
}

export default App;
