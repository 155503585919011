
import {useLazyQuery, useQuery} from "@apollo/client";
import FullSearch from "./components/FullSearch";
import Listings from "./components/Listings";
import SideBar from "./components/SideBar";
import {GET_VENDORS_BY_CATEGORY} from "./graphql";

function VendorsByCategory(props) {

    let search_param = props.search
    let location_param = props.location
    let category = props.category
    let page = props.page


          const {loading, error, data} = useQuery(GET_VENDORS_BY_CATEGORY,{
        variables: {category, page:parseInt(page)}
    });
    if (loading) return ;

    if (error) return <p>Error :(</p>;



    return (

        <div className="container">
            <FullSearch search={search_param} location={location_param}/>

            <div className="row">
                <div className="col-lg-9 col-md-8 padding-right-30">

                    <Listings data={data} />
                </div>
                <SideBar/>
            </div>
        </div>


    );
}

export default VendorsByCategory;




