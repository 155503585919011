import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Index from "./components/Index";
import Footer from "./components/Footer";
import {useParams, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_CATEGORIES_BY_NAME, GET_VENDORS_BY_SEARCH_TERM} from "./graphql";
import Error from "./Error";
import {Helmet} from "react-helmet";


function App(prop) {

    const [searchParams, setSearchParams] = useSearchParams();
    let search_param = searchParams.get("search")
    if (search_param == null) {
        search_param = "Make Up Artist"
    }
    let location_param = searchParams.get("location")

    if (location_param == null || location_param == "") {
        location_param = "United Kingdom"
    }
    let params = useParams(); // calling the hook
    let title = ""
    let query = ""
    let location = ""
    let page = 1
    if (params.slug) {
        title = params.slug;
    }
    ;
    if (params.query) {
        query = params.query;
    }
    ;
    if (params.location) {
        location = params.location;
    }
    ;

    if (params.page) {
        page = params.page;
    }
    ;
    const {loading, error, data} = useQuery(GET_VENDORS_BY_SEARCH_TERM, {
        variables: {"search": query, "page": parseInt(page)}
    });

    if (loading) return ;

    if (error) return <Error/>;


    return (
        <div>
            <Helmet>
                <title>{query} - Asian Wedding Vendors - Kalyanam </title>
            </Helmet>
            <NavBar/>
            <TitleBar title={title}/>
            <Index data={data} search={query} location={location_param}/>
            <Footer/>
        </div>
    );
}

export default App;
