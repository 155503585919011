
import {useParams} from "react-router-dom";
import ServiceItem from "./ServiceItem";

function Services(props) {

    let params = useParams(); // calling the hook
    let slug = "f"

    if (params.slug) {
        slug = params.slug;
    }
    console.log("HELLO")
    console.log(props.data)
    return(


    <ul className="listing-features checkboxes">
        <ServiceItem data={props.data}/>
    </ul>



    );
}

export default Services;



