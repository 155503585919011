import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";

function FullSearch(props) {
  let navigate = useNavigate();
  let client = useApolloClient();

  let [search_param, setSearchParam] = useState(props.search);
  let [location_param, setLocationParam] = useState(props.location);
  let [autocompleteOptions, setAutocompleteOptions] = useState([]);
  let [firstSuggestion, setFirstSuggestion] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/search/" + search_param + "/" + location_param + "/");
  };

  const handleChangeSearch = (event) => {
    setSearchParam(event.target.value);
  };

  const handleChangeLocation = (event) => {
    setLocationParam(event.target.value);
  };

  useEffect(() => {
    const fetchAutocompleteOptions = async () => {
      const AUTOCOMPLETE_QUERY = gql`
        query vendorsBySearchTerm($param: String!, $page: Int!) {
          vendorsBySearchTerm(search: $param, page: $page) {
            objects {
              name
              postcode
              slug
            }
          }
        }
      `;

      const { data } = await client.query({
        query: AUTOCOMPLETE_QUERY,
        variables: { param: search_param, page: 1 },
      });

      setAutocompleteOptions(data.vendorsBySearchTerm.objects);
      if (data.vendorsBySearchTerm.objects.length > 0) {
        setFirstSuggestion(data.vendorsBySearchTerm.objects[0].name);
      }
    };

    fetchAutocompleteOptions();
  }, [search_param]);

  return (
    <form onSubmit={handleClick}>
      <div className="col-sm-20">
        <div className="main-search-input gray-style margin-top-0 margin-bottom-10">
          <div className="main-search-input-item autocomplete-input-container">
            <input
              id="autocomplete"
              type="text"
              value={search_param + firstSuggestion.slice(search_param.length)}
              readOnly
            />
            <input
              id="search"
              onChange={handleChangeSearch}
              type="text"
              value={search_param}
            />
          </div>
          <div className="main-search-input-item location">
            <div id="autocomplete-container">
              <input
                id="location"
                onChange={handleChangeLocation}
                type="text"
                placeholder={location_param}
                defaultValue={location_param}
              ></input>
            </div>
            <a href="#">
              <i className="fa fa-map-marker"></i>
            </a>
          </div>
          {/*These are the autocomplete options*/}

          <button onClick={handleClick} className="button">
            Search
          </button>
        </div>
      </div>
    </form>
  );
}

export default FullSearch;
