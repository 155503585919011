function Footer() {
    return (


<div id="footer" className="margin-top-15">
    <div className="container">
        <div className="row">
            <div className="col-md-5 col-sm-6">
                <img className="footer-logo" src="images/logo-dark.svg" alt=""></img>
                    <br/><br/>
                        <p>Kalyanam is the UK’s leading asian wedding planning destination with one simple mission: to help people get married.</p>
            </div>

            <div className="col-md-4 col-sm-6 ">
                <h4>Helpful Links</h4>
                <ul className="footer-links">
                    <li><a href="#">Login</a></li>
                    <li><a href="#">Sign Up</a></li>
                </ul>

                <ul className="footer-links">
                    <li><a href="https://blog.kalyanam.co.uk">Blog</a></li>
                </ul>
                <div className="clearfix"></div>
            </div>

            <div className="col-md-3  col-sm-12">
                <h4>Contact Us</h4>
                <div className="text-widget">
                    Email: <span>contact@kalyanam.co.uk</span> <br/>
                    <br/>
                </div>

                <ul className="social-icons margin-top-20">
                    <li><a className="facebook" href="#"><i className="icon-facebook"></i></a></li>
                    <li><a className="twitter" href="#"><i className="icon-twitter"></i></a></li>
                    <li><a className="gplus" href="#"><i className="icon-gplus"></i></a></li>
                    <li><a className="vimeo" href="#"><i className="icon-vimeo"></i></a></li>
                </ul>

            </div>

        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="copyrights">© 2023 Kalyanam. All Rights Reserved.</div>
            </div>
        </div>

    </div>

</div>

);
}

export default Footer;






