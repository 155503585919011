function SideBar() {
    return (


        <div className="col-lg-3 col-md-4">
            <div className="sidebar">

                <div className="widget margin-bottom-40">
                    <h3 className="margin-top-0 margin-bottom-30">Filters</h3>

                    <div className="row with-forms">
                        <div className="col-md-12">
                            <input type="text" placeholder="Make Up Artists"/>
                        </div>
                    </div>


                    <br/>


                    <button className="button fullwidth margin-top-25">Update</button>

                </div>

            </div>
        </div>
    );
}

export default SideBar;




