import NavBar from "./components/Header";
import TitleBar from "./components/TitleBar";
import Index from "./components/Index";
import Footer from "./components/Footer";


function Error(prop) {

    return (
        <div>
            <NavBar/>
            <TitleBar title="Woops something went wrong!" />
            <Footer/>
        </div>
    );
}

export default Error;
