import VendorListing from "./VendorListing";
import ListingOptions from "./ListingOptions";
import {createSearchParams, useNavigate} from "react-router-dom";
import {useState} from "react";
import Pagination from "./Pagination";

function Listings(props) {

    let listing_data = []
    let page;
    let pages;
    let hasNext;
    let hasPrev;
    if (props.data) {
        console.log("prop data exists")
        if (props.data.hasOwnProperty("vendorsByCategory")) {
            console.log("bycat")
            page = props.data.vendorsByCategory.page
            pages = props.data.vendorsByCategory.pages
            hasNext = props.data.vendorsByCategory.hasNext
            hasPrev = props.data.vendorsByCategory.hasPrev
            listing_data = props.data.vendorsByCategory.objects
        }

        else if (props.data.hasOwnProperty("vendorsBySearchTerm")) {
            console.log("bysearch")
            page = props.data.vendorsBySearchTerm.page
            pages = props.data.vendorsBySearchTerm.pages
            hasNext = props.data.vendorsBySearchTerm.hasNext
            hasPrev = props.data.vendorsBySearchTerm.hasPrev
            listing_data = props.data.vendorsBySearchTerm.objects
        }
        else if (props.data.hasOwnProperty("tenRandomVendors")) {
            console.log("byrandomvend")
            page = props.data.tenRandomVendors.page
            pages = props.data.tenRandomVendors.pages
            hasNext = props.data.tenRandomVendors.hasNext
            hasPrev = props.data.tenRandomVendors.hasPrev
            listing_data = props.data.tenRandomVendors.objects
        }
    }

    return (

        <div>
            <div className="row margin-bottom-25">

                <ListingOptions></ListingOptions>
            </div>
                <Pagination page={page} pages={pages} hasNext={hasNext} hasPrev={hasPrev}/>

            <div className="row">

                <VendorListing data={listing_data}/>
            </div>

            <div className="clearfix"></div>
            <Pagination page={page} pages={pages} hasNext={hasNext} hasPrev={hasPrev}/>

        </div>

    );
}

export default Listings;




