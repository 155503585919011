function PaginationNumbers(props) {
    let page = props.page
    let pages = props.pages
    let hasNext = props.hasNext
    let hasPrev = props.hasPrev
    // <li><a href="1" className="current-page">1</a></li>
    // <li><a href="#"><i className="sl sl-icon-arrow-right"></i></a></li>
    return props.pages_data.map(({page, class_name}) => (


                            <li><a href={page} className={class_name}>{page}</a></li>



    ));
}

export default PaginationNumbers;






