
import {useParams} from "react-router-dom";

function VendorListing(props) {
    let params = useParams(); // calling the hook
    let slug = "f";

    if (params.slug) {
        slug = params.slug;
    }

    const defaultImageUrl = "http://demo.kalyanam.co.uk/logo.png"; // Replace this with your default image URL

    return props.data.map(({ name, slug, postcode, image }) => (
        <div className="col-lg-6 col-md-12">
            <a href={"/listing/" + slug} className="listing-item-container">
                <div className="listing-item">
                    <img
                        src={image && image.cloudflareUrl ? image.cloudflareUrl : defaultImageUrl}
                        alt=""
                    ></img>
                    <div className="listing-badge now-open">New</div>
                    <div className="listing-item-content">
                        <span className="tag">{props.vendor_category}</span>
                        <h3>
                            {name} <i className="verified-icon"></i>
                        </h3>
                        <span>{postcode}</span>
                    </div>
                </div>
                <div className="star-rating" data-rating="3.5">
                    <div className="rating-counter">(12 reviews)</div>
                </div>
            </a>
        </div>
    ));
}

export default VendorListing;



