function ListingSideBar() {
    return (

        <div class="col-lg-4 col-md-4 margin-top-75 sticky">


            <div class="verified-badge with-tip"
                 data-tip-content="Listing has been verified and belongs the business owner or manager.">
                <i class="sl sl-icon-check"></i> Verified Listing
            </div>




            <div class="boxed-widget opening-hours margin-top-35">
                <div class="listing-badge now-open">Now Open</div>
                <h3><i class="sl sl-icon-clock"></i> Opening Hours</h3>
                <ul>
                    <li>Monday <span>9 AM - 5 PM</span></li>
                    <li>Tuesday <span>9 AM - 5 PM</span></li>
                    <li>Wednesday <span>9 AM - 5 PM</span></li>
                    <li>Thursday <span>9 AM - 5 PM</span></li>
                    <li>Friday <span>9 AM - 5 PM</span></li>
                    <li>Saturday <span>9 AM - 3 PM</span></li>
                    <li>Sunday <span>Closed</span></li>
                </ul>
            </div>


            <div class="boxed-widget margin-top-35">
                <div class="hosted-by-title">
                    <h4><span>Hosted by</span> <a href="pages-user-profile.html">Tom Perrin</a></h4>
                    <a href="pages-user-profile.html" class="hosted-by-avatar"><img
                        src="images/dashboard-avatar.jpg" alt=""></img></a>
                </div>
                <ul class="listing-details-sidebar">
                    <li><i class="sl sl-icon-phone"></i> (123) 123-456</li>
                    <li><i class="fa fa-envelope-o"></i> <a href="#"><span class="__cf_email__"
                                                                           data-cfemail="384c5755785d40595548545d165b5755">[email&#160;protected]</span></a>
                    </li>
                </ul>

                <ul class="listing-details-sidebar social-profiles">
                    <li><a href="#" class="facebook-profile"><i class="fa fa-facebook-square"></i> Facebook</a>
                    </li>
                    <li><a href="#" class="twitter-profile"><i class="fa fa-twitter"></i> Twitter</a></li>
                </ul>

                <div id="small-dialog" class="zoom-anim-dialog mfp-hide">
                    <div class="small-dialog-header">
                        <h3>Send Message</h3>
                    </div>
                    <div class="message-reply margin-top-0">
                        <textarea cols="40" rows="3" placeholder="Your message to Tom"></textarea>
                        <button class="button">Send Message</button>
                    </div>
                </div>

                <a href="#small-dialog" class="send-message-to-owner button popup-with-zoom-anim"><i
                    class="sl sl-icon-envelope-open"></i> Send Message</a>
            </div>


            <div class="listing-share margin-top-40 margin-bottom-40 no-border">
                <button class="like-button"><span class="like-icon"></span> Bookmark this listing</button>
                <span>159 people bookmarked this place</span>

                <ul class="share-buttons margin-top-40 margin-bottom-0">
                    <li><a class="fb-share" href="#"><i class="fa fa-facebook"></i> Share</a></li>
                    <li><a class="twitter-share" href="#"><i class="fa fa-twitter"></i> Tweet</a></li>
                    <li><a class="gplus-share" href="#"><i class="fa fa-google-plus"></i> Share</a></li>
                </ul>
                <div class="clearfix"></div>
            </div>

        </div>

    )
        ;
}

export default ListingSideBar;




