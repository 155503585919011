import {useParams} from "react-router-dom";

function Services(props) {

    let params = useParams(); // calling the hook
    let slug = "f"

    if (params.slug) {
        slug = params.slug;
    }
    console.log("HELLO")
    console.log(slug)
    let services_offered = props.data.servicesOffered

    let all_services = props.data.services

       return (
        <ul>
            {all_services.map(service => {
                // Determine whether the service is offered
                const isOffered = services_offered.some(offeredService => offeredService.name === service.name);

                // Use the result to choose the right className and symbol
                const className = isOffered ? "offered" : "not-offered";
                const symbol = isOffered ? '✓' : 'x';

                return (
                    <li key={service.name} className={className}>
                        {service.name}
                    </li>
                );
            })}
        </ul>
    );
}



export default Services;



