import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/style.css';
import './css/FullSearch.css';
import './css/colors/main.css';
import {ApolloProvider} from '@apollo/client';
import {client} from "./graphql";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import SignIn from "./SignIn";
import Listing from "./Listing";
import CategoryListings from "./CategoryListings";
import Search from "./Search";
import Header from "./components/Header";
import HTMLHeader from "./HTMLHeader";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,

    },
    {
        path: "/:page",
        element: <App/>,

    },
    {
        path: "/asian-wedding-vendors/:slug",
        element: <CategoryListings/>
    },
    {
        path: "/asian-wedding-vendors/:slug/:page",
        element: <CategoryListings/>
    },
    {
        path: "/sign-in",
        element: <SignIn/>
    },
    {
        path: "/search/:query/:location/:page",
        element: <Search/>
    },
    {
        path: "/search/:query/:location/",
        element: <Search/>
    },
    {
        path: "/listing/:slug",
        element: <Listing/>
    },
]);

const router_header = createBrowserRouter([
    {
        path: "/*",
        element: <HTMLHeader/>,

    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={client}>
        <RouterProvider router={router}/>

    </ApolloProvider>,
);

const header = ReactDOM.createRoot(document.getElementById('header'));
header.render(
    <ApolloProvider client={client}>
        <RouterProvider router={router_header}/>

    </ApolloProvider>,
);