import {ApolloClient, gql, InMemoryCache} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client'

const client = new ApolloClient({
    link: createUploadLink({
        uri: 'https://staging.kalyanam.co.uk/graphql',
        headers: {}
    }),
    cache: new InMemoryCache()
})

const GET_CATEGORIES =

    gql`
  query GetCategories {
    categories {
    name
    menuOrder
    slug
    childCategories {
      name
      menuOrder
      slug
    }
  }
}

`;

const GET_CATEGORIES_BY_NAME =

    gql`
 query ($slug: String!) {
  categoryByName (name:$slug) {
    name
    menuOrder
    parentCategory {
      name
      menuOrder
    }
  }
}
`;

const GET_SERVICES_BY_CATEGORY =

    gql`
 query ($slug: String!) {
  servicesByCategory (slug:$slug) {
     name
    }
  }
`;
const GET_VENDORS_BY_CATEGORY =

    gql`
 query ($category: String! $page: Int!) {
  vendorsByCategory (category:$category page:$page) {
     page
      pages
      hasNext
      hasPrev
      objects {
        name
        postcode
        slug
      }
    }
  }
`;

const GET_VENDORS_BY_SEARCH_TERM =

    gql`
 query ($search: String! $page: Int!) {
  vendorsBySearchTerm (search:$search page:$page) {
      page
      pages
      hasNext
      hasPrev
      objects {
        name
        postcode
        slug
        image {
            cloudflareUrl
        }
      }
    }
  }
`;

const GET_VENDOR_BY_SLUG =

    gql`
        query ($slug: String!) {
            vendorBySlug (slug:$slug) {
                name
                postcode
                slug
                seoDescription
                keywords
                services
                {
                    name
                }
                servicesOffered
                {
                    name
                }
                categories
                {
                    name
                }
            }
        }
    `;

const TEN_RANDOM_VENDORS =

    gql`
 query TenRandomVendors($page: Int!) {
      tenRandomVendors(page: $page) {
      page
      pages
      hasNext
      hasPrev
      objects {
        name
        slug
      }
    }
  }
`;


export {
    client,
    GET_CATEGORIES,
    GET_CATEGORIES_BY_NAME,
    GET_VENDORS_BY_CATEGORY,
    GET_VENDORS_BY_SEARCH_TERM,
    TEN_RANDOM_VENDORS,
    GET_VENDOR_BY_SLUG,
    GET_SERVICES_BY_CATEGORY
};