import SideBar from "./SideBar";
import Listings from "./Listings";
import FullSearch from "./FullSearch";
import {Helmet} from "react-helmet";

function Index(props) {

    let search_param = props.search
    let location_param = props.location
    let data = props.data
    return (

        <div className="container">

            <FullSearch search={search_param} location={location_param}/>

            <div className="row">
                <div className="col-lg-9 col-md-8 padding-right-30">

                    <Listings data={data}/>
                </div>
                <SideBar/>
            </div>
        </div>


    );
}

export default Index;




