import React from 'react';
import PaginationNumbers from './PaginationNumbers';

function Pagination(props) {
    let current_page = props.page;
    let pages = props.pages;
    let hasNext = props.hasNext;
    let hasPrev = props.hasPrev;
    let displayLimit = 2;  // Limit of pages displayed around the current page
    let pages_data = [];
    let classname;

    for (let page = Math.max(1, current_page - displayLimit); page <= Math.min(pages, current_page + displayLimit); page++) {
        if (current_page === page){
            classname = "current-page";
        } else {
             classname = "";
        }
        pages_data.push({"page": page, "class_name": classname });
    }

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="pagination-container margin-top-20 margin-bottom-40">
                    <nav className="pagination">
                        <ul>
                            { hasPrev && <li className="pagination-prev"><a href={props.page-1}><i className="sl sl-icon-arrow-left"></i></a></li> }
                            <PaginationNumbers pages_data={pages_data} />
                            { hasNext && <li className="pagination-next"><a href={props.page+1}><i className="sl sl-icon-arrow-right"></i></a></li> }
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Pagination;